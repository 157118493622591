import React from "react";

const Settings = () => {
  return (
    <main>
      <div className="container-fluid">
        <h3>Settings</h3>
      </div>
    </main>
  );
};

export default Settings;
