import React, { createContext, useEffect, useState } from "react";

export const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState();
  const [user, setUser] = useState(JSON.parse(JSON.stringify(localStorage.getItem('user'))))

  return (
    <StoreContext.Provider value={{ stores, selectedStore, setSelectedStore, setStores }}>
      {children}
    </StoreContext.Provider>
  );
};
